import React, { useState } from "react"
import { Col } from "react-bootstrap"
import SwiperCore, { Autoplay, EffectCoverflow } from "swiper/core"
import "swiper/css"
import { Swiper, SwiperSlide } from "swiper/react"
import SVGIcon from "../SVGIcon"
import "./swiper-carousel-new.scss"

SwiperCore.use([EffectCoverflow, Autoplay])

export default function SwiperCarousel(props) {
  const [swipe, setSwipe] = useState()
  const [CurrentTestimonial, setCurrentTestimonial] = useState(0)
  const responsive = {
    992: {
      slidesPerView: "auto",
    },
    734: {
      slidesPerView: 1,
    },
    200: {
      slidesPerView: 1,
    },
  }
  function addClass() {
    document
      .getElementById("animae-wrapper")
      ?.classList.add("author-wraper--active")
    setTimeout(
      () =>
        document
          .getElementById("animae-wrapper")
          ?.classList.remove("author-wraper--active"),
      700
    )
  }
  const CustomButtonGroup = () => {
    return (
      <>
        <button
          className="carousel-btn carousel-btn--prev"
          onClick={() => {
            swipe.slidePrev()
          }}
        >
          <SVGIcon
            name="mspawards/arrow"
            className="carousel-btn-arrow--left"
          />
        </button>
        <button
          className="carousel-btn carousel-btn--next"
          onClick={() => {
            swipe.slideNext()
          }}
        >
          <SVGIcon
            name="mspawards/arrow"
            className="carousel-btn-arrow--right"
          />
        </button>
      </>
    )
  }
  const onchange = data => {
    setCurrentTestimonial(data)
  }
  return (
    <>
      <div className="prelative">
        <div
          className="justify-content-center d-flex author-wraper mb64"
          id="animae-wrapper"
        >
          <Col lg={7} className="prelative">
            <SVGIcon
              name="home/quote-home"
              className="quote-icon position-absolute"
            />
            <div className="content p20 d-flex align-items-center justify-content-center">
              {props.testimonial.testimonialName.map((t, i) => (
                <p
                  className={`author-words text-center fw-600 ${
                    CurrentTestimonial === i
                      ? "textReveal"
                      : "fade-out-effect dspnone"
                  } `}
                >
                  {props.testimonial.testimonialContent[i]}
                </p>
              ))}
            </div>
          </Col>
        </div>

        <div className="caro-container prelative">
          <CustomButtonGroup />
          <div className="swiper-container">
            <Swiper
              pagination={false}
              effect={"fade"}
              centeredSlides={true}
              spaceBetween={70}
              slidesPerView={"auto"}
              loop={true}
              onSlideChange={data => {
                onchange(data.realIndex)
              }}
              onBeforeInit={swipper => setSwipe(swipper)}
              speed={750}
              breakpoints={responsive}
              slideToClickedSlide={true}
              fadeEffect={{
                crossFade: true,
              }}
              className="mySwiper"
            >
              <SVGIcon
                name="home/square-grunge"
                className="position-absolute grunge"
              />
              {props.testimonial.testimonialName.map((singletest, i) => (
                <SwiperSlide>
                  <div className="people-conatiner">
                    <div className="people-image overflow-hidden">
                      <img
                        src={props.testimonial.testimonialImage[i].url}
                        alt="img"
                        className="w-100"
                        decoding="async"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              {props.testimonial.testimonialName.map((t, i) => (
                <div
                  className={`name-wrap p16 mt80 mx-auto text-center  ${
                    CurrentTestimonial === i
                      ? "textReveal "
                      : "fade-out-effect dspnone"
                  }`}
                >
                  <h5 className="fw-bold">{t}</h5>
                  <p className="text-grey m-0 fw-500">
                    {props.testimonial.testimonialDescription[i]}
                  </p>
                  <p className="text-grey fw-500">
                    {props.testimonial.testimonialLink[i]}
                  </p>
                </div>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  )
}
