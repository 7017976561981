import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas"
import { Link, StaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import TrackVisibility from "react-on-screen"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import CTA from "../../components/common/CTA.js"
import LatestResources from "../../components/common/Latest-Resources-Section/latest-all-resources"
import SVGIcon from "../../components/common/SVGIcon"
import SwiperCarousel from "../../components/common/Swiper-Carousel/swiper-carousel-new"
import VideoSection from "../../components/common/Video-section/VideoSection"
import BadgesV2 from "../../components/common/badges/BadgesV2"
import Buttons from "../../components/common/button"
import CarouselComponent from "../../components/common/carousel-component"
import Frame from "../../components/common/frame"
import IntegrationsCardNew from "../../components/common/integration-tools-card-new"
import Navigation from "../../components/navigation"
import Typer from "../../components/utility/typewriter"
import "../../styles/pages/home-2023.scss"
const RmmTools = props => {
  const [activeTab, setActiveTab] = useState(0)
  const [show, setShow] = useState(false)
  const responsiveAll = {
    allScreen: {
      breakpoint: { max: 4000, min: 0 },
      items: 1.3,
      slidesToSlide: 1,
    },
  }

  useEffect(() => {
    if (window.innerWidth < 500) {
      setShow(true)
    }
  }, [])

  const { rive, RiveComponent } = useRive({
    src: "https://media.graphassets.com/xs0yV653T7ioo0JKKZag",
    stateMachines: "Scene",
    onPlay: e => {
      setTimeout(setShow(true), 300)
    },
    onLoadError: e => {
      setShow(true)
      console.log("RiveAnimationError")
    },
    autoplay: true,
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.TopCenter,
    }),
  })

  const DropdownContents = props => {
    let { el } = props
    return (
      <div className="position-relative">
        <div className="link-box br10 position-relative d-flex">
          {
            <>
              {el.icon && (
                <div className="icon-wrapper">
                  <img
                    decoding="async"
                    className="icon"
                    src={el.icon?.url}
                    alt="icon"
                  />
                </div>
              )}
              <div className="position-relative">
                {el.isNew === "Yes" && (
                  <p className="tag position-absolute m-0">NEW</p>
                )}
                <div className="p16">
                  <p className="title text-deep-purple fw-500">
                    {el.heading}
                    {props.rightArrow && (
                      <SVGIcon
                        name="hypertext-arrow-right"
                        className="arrow-right position-absolute"
                      />
                    )}
                  </p>
                </div>
                <div className="p14">
                  <p className="subtext text-grey m-0">{el.subText}</p>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }

  const setSessStorage = id => {
    if (id) sessionStorage.setItem("pageBlockScrollId", id)
  }

  const featureTabScroll = () => {
    const featTabChk = document.getElementById("featureTabSection")
    window.scrollTo({
      top: featTabChk.offsetTop - window.innerHeight,
      behavior: "smooth",
    })
  }

  return (
    <div className="home-2023">
      <StaticQuery
        query={graphql`
          query rmmTools {
            SuperOps {
              pages(where: { title: "HomeV5.0" }) {
                title
                linkTitle
                heroImage {
                  url
                }
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                links {
                  authorName
                  linkName
                  icon {
                    url
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                pageImages {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                navigationBlock {
                  navigationLinks {
                    heading
                    subText
                    link
                    image {
                      url
                    }
                    childFooter
                    childFooterLink
                    featureNavigationLinks {
                      heading
                      subText
                      link
                      icon {
                        url
                      }
                      iconBgColor {
                        css
                      }
                      childHeader
                    }
                  }
                }
                ctaBoxes {
                  type
                  theme
                  customBackgroundColor
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                  card {
                    cardName
                    title
                    subtext
                    slug
                  }
                }
                faqComponent {
                  heading
                  description
                  faqType
                  faqs {
                    category
                    question
                    answerrt {
                      html
                    }
                    images {
                      url
                    }
                    faqImage {
                      url
                    }
                    slug
                    linkText
                    imageBlocks {
                      blockImageTitle
                      blockImage {
                        url
                      }
                      blockIsVideo
                      blockIsIllustration
                    }
                  }
                  ctaHeading
                  ctaText {
                    html
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    primaryButtonText
                    primaryButtonLink
                    secondaryButtonText
                    secondaryButtonLink
                    heroCtaText
                    heroExcerptList
                    headingLine1
                    animAltText
                    headingLine2
                    backgroundImage {
                      url
                    }
                  }
                  ... on SuperOps_Testimonial {
                    testimonialTitle
                    testimonialName
                    testimonialDescription
                    testimonialContent
                    testimonialLink
                    testimonialImage {
                      url
                    }
                  }
                  ... on SuperOps_Card {
                    title
                    heading
                    cardName
                    slug
                    links
                    text
                    subtext
                    image {
                      url
                    }
                  }
                }
              }
              peopleList(where: { page_contains: "Home" }) {
                page
                name
                title
                testimonial
                background
                cardBgColor
                thumbnail {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 110 } }
                    }
                  )
                }
                picture {
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 500 } }
                    }
                  )
                }
                titleText {
                  html
                }
                descriptionText {
                  html
                }
              }
              seoPosts(where: { pageName: Integrations }, orderBy: order_ASC) {
                order
                slug
                integrationCards {
                  cardName
                  subtext
                  slug
                  image {
                    url(
                      transformation: { document: { output: { format: webp } } }
                    )
                  }
                }
              }
              navigationContents {
                navigation
                section
                parentTitle
                parentDescription
                childTitle
                childSlug
                childDescription
                childIcon {
                  url
                }
                parentIllustration {
                  url
                }
                boxBg
              }
              posts(first: 3, orderBy: date_DESC, where: { isBlog: Yes }) {
                title
                coverImage {
                  id
                  url(
                    transformation: {
                      document: { output: { format: webp } }
                      image: { resize: { width: 770 } }
                    }
                  )
                }
                tags
                slug
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const {
                linkTitle,
                pageBlock,
                navigationBlock,
                seo,
                pageImages,
                ctaBoxes,
                newField,
              } = page
              const heroSection = newField[0]
              const videoSection = newField[2]
              const navigationLinks = navigationBlock[0].navigationLinks

              const integrations = data.SuperOps.seoPosts
              const allIntegrations = [...integrations, ...integrations]
              const layer1 = []
              const layer2 = []
              const layer3 = []
              allIntegrations.map((el, id) => {
                if (id < 5) layer1.push(el)
                else if (id > 4 && id < 11) layer2.push(el)
                else if (id > 10 && id < 15) layer3.push(el)
              })
              return (
                <div>
                  <Frame
                    title={seo.title}
                    description={seo.description}
                    keywords={seo.keywords}
                    ogTitle={seo.title}
                    ogDescription={seo.description}
                    ogImage={seo.image}
                    ogType="website"
                    footerClass={"p-0"}
                    newButton
                    lazyLoad
                  >
                    <header>
                      <Navigation page="Home" color="#FFE188" newButton />
                    </header>
                    <main>
                      <section className="hero justify-content-center prelative">
                        <div className="position-absolute mx-auto d-dsk">
                          <RiveComponent
                            className="rive-board"
                            src={heroSection.backgroundImage.url}
                            stateMachines="Scene"
                            layout={
                              new Layout({
                                fit: Fit.Fill,
                                alignment: Alignment.TopCenter,
                              })
                            }
                          />
                        </div>

                        <Container className="Layout-container">
                          <Row className="hero-row justify-content-center p24">
                            <Col lg={7} className="mx-auto text-center">
                              <Fade bottom delay={400} duration={1000}>
                                <h1 className="fw-bold text-center mb50 main-title">
                                  {heroSection.headingLine1}
                                  <div>
                                    <Typer
                                      sentences={heroSection.animAltText}
                                      initialText
                                      loop={false}
                                      startDelay={2000}
                                      typingSpeed={90}
                                      deletingSpeed={70}
                                      cursorClassName={"animCursor"}
                                      hideCursorOnFinish={true}
                                      cursorSmooth={true}
                                    ></Typer>{" "}
                                    {heroSection.headingLine2}
                                  </div>
                                </h1>
                                <Fade delay={450} bottom distance="80px">
                                  <div className="pink-border mx-auto mb50"></div>
                                </Fade>
                              </Fade>
                              <Fade bottom delay={500} duration={1000}>
                                <p className="mb38 fw-500">
                                  {heroSection.heroCtaText}
                                </p>
                              </Fade>
                              <Fade bottom delay={550} duration={1000}>
                                <div className="d-flex fw-600 justify-content-center cg-10 flex-wrap">
                                  <p className="m-0">
                                    {heroSection.heroExcerptList[0]}
                                  </p>
                                  <div className="prelative">
                                    <SVGIcon
                                      name="home/stroke-grunge"
                                      className="position-absolute yellow-highlight"
                                    />
                                    <p className="m-0 text-pink">
                                      {heroSection.heroExcerptList[1]}
                                    </p>
                                  </div>
                                </div>
                                <p className="fw-600 mb64">
                                  {heroSection.heroExcerptList[2]}
                                </p>
                              </Fade>
                              <Fade bottom delay={600} duration={1000}>
                                <div className="buttons">
                                  <Buttons
                                    theme="primary-new"
                                    link={heroSection.primaryButtonLink}
                                    text={heroSection.primaryButtonText}
                                  />
                                  <Buttons
                                    theme="secondary-new"
                                    link={heroSection.secondaryButtonLink}
                                    text={heroSection.secondaryButtonText}
                                  />
                                </div>
                              </Fade>
                            </Col>
                          </Row>
                        </Container>
                      </section>

                      <div className="testimonial-video-section mx-auto">
                        <LazyLoad>
                          <section
                            className="testimonials-section mb64 mx-auto"
                            // style={{ opacity: show ? 1 : 0 }}
                          >
                            <Fade bottom duration={1000}>
                              <BadgesV2
                                heading={parse(pageBlock[0].content.text)}
                                images={pageImages}
                              />
                            </Fade>
                            <Container className="Layout-container">
                              <div className="mt100">
                                <Fade bottom duration={1000} delay={100}>
                                  <SwiperCarousel testimonial={newField[1]} />
                                </Fade>
                              </div>
                            </Container>
                          </section>
                        </LazyLoad>
                        <LazyLoad>
                          <section className="home-video-section">
                            <Fade bottom duration={1000}>
                              <VideoSection
                                heading={videoSection.cardName}
                                videoURL={videoSection.slug}
                                videoScreenshot={videoSection.image[0]}
                                subHeadings={videoSection.title}
                              />
                            </Fade>
                          </section>
                        </LazyLoad>
                      </div>

                      <section
                        className="feature-tabs m-0 prelative"
                        style={{
                          background: `linear-gradient(${linkTitle[activeTab]})`,
                        }}
                      >
                        <Container className="Layout-container">
                          <center>
                            <Fade bottom duration={500}>
                              <div className="main-title fw-bold">
                                {parse(pageBlock[1].content.html)}
                              </div>

                              <div className="p18 mt16">
                                {parse(pageBlock[2].content.html)}
                              </div>
                            </Fade>
                          </center>

                          <Row className="justify-content-between flex-column mt80">
                            <div className="d-mob">
                              {navigationLinks.map((el, id) => {
                                const featureNavigationLinks =
                                  el.featureNavigationLinks
                                return (
                                  <>
                                    <div className="img-wrapper mx-auto mb30">
                                      <img
                                        src={el.image.url}
                                        alt="featureImage"
                                        className="w-100"
                                      />
                                    </div>
                                    <div className="p24 mt24 mb16 fw-600">
                                      <p className="m-0">{el.heading}</p>
                                    </div>

                                    <div className="altv1 heading-wrapper">
                                      <h4 className="fw-600 m-0 mt40">
                                        {el.subText}
                                      </h4>
                                    </div>
                                    <div className="prelative mt38 mb64">
                                      <div className="feature-links">
                                        <CarouselComponent
                                          responsive={responsiveAll}
                                          swipeable
                                          customButtonNew
                                          partialVisbile
                                          customButtonclass="home-carousel"
                                        >
                                          {featureNavigationLinks
                                            .slice(0, 4)
                                            .map((el2, idx2) => {
                                              if (idx2 < 4) {
                                                return el2.link ? (
                                                  <div
                                                    onClick={() =>
                                                      setSessStorage(
                                                        el2.childHeader
                                                      )
                                                    }
                                                  >
                                                    <Link to={el2.link}>
                                                      <DropdownContents
                                                        el={el2}
                                                        rightArrow
                                                      />
                                                    </Link>
                                                  </div>
                                                ) : (
                                                  <DropdownContents el={el2} />
                                                )
                                              }
                                            })}
                                        </CarouselComponent>
                                      </div>
                                      <Link
                                        to={`/${el.childFooterLink}`}
                                        className="d-flex align-items-center title-link fw-500 position-absolute more-link mt32"
                                      >
                                        {el.childFooter}
                                        <SVGIcon
                                          name="hypertext-arrow-right"
                                          className="arrow-right"
                                        />
                                      </Link>
                                    </div>
                                  </>
                                )
                              })}
                            </div>
                            <Fade bottom duration={500}>
                              <Col lg={12} className="content">
                                {navigationLinks.map((el, id) => {
                                  const featureNavigationLinks =
                                    el.featureNavigationLinks
                                  return (
                                    <div
                                      className="d-dsk"
                                      style={{
                                        display:
                                          activeTab === id ? "block" : "none",
                                      }}
                                    >
                                      <Row>
                                        <Col lg={6} className="left">
                                          <Fade
                                            bottom
                                            distance={"60px"}
                                            duration={750}
                                          >
                                            <div className="img-wrapper mx-auto">
                                              <img
                                                src={el.image.url}
                                                alt="featureImage"
                                                className="w-100"
                                              />
                                            </div>
                                          </Fade>
                                        </Col>
                                        <Col
                                          lg={6}
                                          className="right slide-from-left"
                                        >
                                          <div className="altv1 heading-wrapper">
                                            <h4 className="fw-600 m-0 mt40">
                                              {el.subText}
                                            </h4>
                                          </div>
                                          <div className="d-flex flex-wrap feature-links mt60">
                                            {featureNavigationLinks.map(
                                              (el2, idx2) => {
                                                if (idx2 < 4) {
                                                  return el2.link ? (
                                                    <div
                                                      onClick={() =>
                                                        setSessStorage(
                                                          el2.childHeader
                                                        )
                                                      }
                                                    >
                                                      <Link to={el2.link}>
                                                        <DropdownContents
                                                          el={el2}
                                                          rightArrow
                                                        />
                                                      </Link>
                                                    </div>
                                                  ) : (
                                                    <DropdownContents
                                                      el={el2}
                                                    />
                                                  )
                                                }
                                              }
                                            )}
                                          </div>
                                          <Link
                                            to={`/${el.childFooterLink}`}
                                            className="d-flex align-items-center title-link link-absolute mt32"
                                          >
                                            {el.childFooter}
                                            <SVGIcon
                                              name="hypertext-arrow-right"
                                              className="arrow-right"
                                            />
                                          </Link>
                                        </Col>
                                      </Row>
                                    </div>
                                  )
                                })}
                              </Col>

                              <Col lg={12} className="tabs mt60">
                                <Slide delay="200">
                                  <div className="d-dsk tab-wrapper">
                                    <div className="fpd-wrap fe-gl-nav-dsk justify-content-between">
                                      {navigationLinks.map((el, id) => {
                                        return (
                                          <div
                                            className={`fgl-nav p24
                                        ${id === activeTab && "active"}
                                          `}
                                            onClick={() => setActiveTab(id)}
                                            onKeyDown={() => setActiveTab(id)}
                                            role="button"
                                            tabIndex={0}
                                          >
                                            <div className="at-line" />
                                            <p className="fw-600  m-0 mt24">
                                              {el.heading}
                                            </p>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </Slide>
                              </Col>
                            </Fade>
                          </Row>
                        </Container>
                      </section>

                      <section className="integrations-section">
                        <div className="wrapper mx-auto">
                          <Col
                            lg={12}
                            className="right prelative"
                            style={{ height: "600px" }}
                          >
                            <div className="prelative d-flex flex-column integration-parent-wrapper">
                              <IntegrationsCardNew data={layer1} />
                              <IntegrationsCardNew data={layer2} />
                              <IntegrationsCardNew data={layer3} />
                            </div>
                            <div className="position-absolute line-wrapper"></div>
                          </Col>
                          <Col lg={12} className="left">
                            <div className="position-relative mx-auto">
                              <Fade bottom duration={1000}>
                                <div className="heading position-relative justify-content-center fw-bold">
                                  {parse(pageBlock[3].content.html)}
                                </div>
                              </Fade>
                            </div>
                          </Col>
                          <div className="d-flex justify-content-center mt32">
                            <Buttons
                              theme="secondary-new"
                              link={"/marketplace"}
                              text={parse(pageBlock[4].content.html)}
                            />
                          </div>
                        </div>
                      </section>

                      <div className="testimonial-video-section mx-auto">
                        <LazyLoad>
                          <section
                            className="testimonials-section mb64 mx-auto"
                            style={{ opacity: show ? 1 : 0 }}
                          >
                            <Fade bottom duration={1000}>
                              <BadgesV2
                                heading={parse(pageBlock[0].content.text)}
                                images={pageImages}
                              />
                            </Fade>
                            <Container className="Layout-container">
                              <div className="mt100">
                                <Fade bottom duration={1000} delay={100}>
                                  <SwiperCarousel testimonial={newField[1]} />
                                </Fade>
                              </div>
                            </Container>
                          </section>
                        </LazyLoad>
                        <LazyLoad>
                          <section className="home-video-section">
                            <Fade bottom duration={1000}>
                              <VideoSection
                                heading={videoSection.cardName}
                                videoURL={videoSection.slug}
                                videoScreenshot={videoSection.image[0]}
                                subHeadings={videoSection.title}
                              />
                            </Fade>
                          </section>
                        </LazyLoad>
                      </div>
                      <LazyLoad>
                        <section
                          className="feature-tabs m-0 prelative"
                          style={{
                            background: `linear-gradient(${linkTitle[activeTab]})`,
                          }}
                        >
                          <TrackVisibility once offset={200}>
                            {({ isVisible }) => {
                              return (
                                isVisible && (
                                  <Container className="Layout-container">
                                    <center>
                                      <Fade bottom duration={1000}>
                                        <div className="main-title fw-bold">
                                          {parse(pageBlock[1].content.html)}
                                        </div>

                                        <div className="p18 mt16">
                                          {parse(pageBlock[2].content.html)}
                                        </div>
                                      </Fade>
                                    </center>

                                    <Row className="justify-content-between flex-column mt80">
                                      <div className="d-mob">
                                        {navigationLinks.map((el, id) => {
                                          const featureNavigationLinks =
                                            el.featureNavigationLinks
                                          return (
                                            <>
                                              <div className="img-wrapper mx-auto mb30">
                                                <img
                                                  decoding="async"
                                                  src={el.image.url}
                                                  alt="featureImage"
                                                  className="w-100"
                                                />
                                              </div>
                                              <div className="p24 mt24 mb16 fw-600">
                                                <p className="m-0">
                                                  {el.heading}
                                                </p>
                                              </div>

                                              <div className="altv1 heading-wrapper">
                                                <h4 className="fw-600 m-0 mt40">
                                                  {el.subText}
                                                </h4>
                                              </div>
                                              <div className="prelative mt38 mb64">
                                                <div className="feature-links">
                                                  <CarouselComponent
                                                    responsive={responsiveAll}
                                                    swipeable
                                                    customButtonNew
                                                    partialVisbile
                                                    customButtonclass="home-carousel"
                                                  >
                                                    {featureNavigationLinks
                                                      .slice(0, 4)
                                                      .map((el2, idx2) => {
                                                        if (idx2 < 4) {
                                                          return el2.link ? (
                                                            <div
                                                              onClick={() =>
                                                                setSessStorage(
                                                                  el2.childHeader
                                                                )
                                                              }
                                                            >
                                                              <Link
                                                                to={el2.link}
                                                              >
                                                                <DropdownContents
                                                                  el={el2}
                                                                  rightArrow
                                                                />
                                                              </Link>
                                                            </div>
                                                          ) : (
                                                            <DropdownContents
                                                              el={el2}
                                                            />
                                                          )
                                                        }
                                                      })}
                                                  </CarouselComponent>
                                                </div>
                                                <Link
                                                  to={`/${el.childFooterLink}`}
                                                  className="d-flex align-items-center title-link fw-500 position-absolute more-link mt32"
                                                >
                                                  {el.childFooter}
                                                  <SVGIcon
                                                    name="hypertext-arrow-right"
                                                    className="arrow-right"
                                                  />
                                                </Link>
                                              </div>
                                            </>
                                          )
                                        })}
                                      </div>
                                      <Fade bottom duration={1000}>
                                        <Col lg={12} className="content">
                                          {navigationLinks.map((el, id) => {
                                            const featureNavigationLinks =
                                              el.featureNavigationLinks
                                            return (
                                              <div
                                                className="d-dsk"
                                                style={{
                                                  display:
                                                    activeTab === id
                                                      ? "block"
                                                      : "none",
                                                }}
                                              >
                                                <Row>
                                                  <Col lg={6} className="left">
                                                    <Fade
                                                      bottom
                                                      distance={"60px"}
                                                      duration={1000}
                                                    >
                                                      <div className="img-wrapper mx-auto">
                                                        <img
                                                          src={el.image.url}
                                                          alt="featureImage"
                                                          className="w-100"
                                                          decoding="async"
                                                        />
                                                      </div>
                                                    </Fade>
                                                  </Col>
                                                  <Col
                                                    lg={6}
                                                    className="right slide-from-left"
                                                  >
                                                    <div className="altv1 heading-wrapper">
                                                      <h4 className="fw-600 m-0 mt40">
                                                        {el.subText}
                                                      </h4>
                                                    </div>
                                                    <div className="d-flex flex-wrap feature-links mt60">
                                                      {featureNavigationLinks.map(
                                                        (el2, idx2) => {
                                                          if (idx2 < 4) {
                                                            return el2.link ? (
                                                              <div
                                                                onClick={() =>
                                                                  setSessStorage(
                                                                    el2.childHeader
                                                                  )
                                                                }
                                                              >
                                                                <Link
                                                                  to={el2.link}
                                                                >
                                                                  <DropdownContents
                                                                    el={el2}
                                                                    rightArrow
                                                                  />
                                                                </Link>
                                                              </div>
                                                            ) : (
                                                              <DropdownContents
                                                                el={el2}
                                                              />
                                                            )
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                    <Link
                                                      to={`/${el.childFooterLink}`}
                                                      className="d-flex align-items-center title-link link-absolute mt32"
                                                    >
                                                      {el.childFooter}
                                                      <SVGIcon
                                                        name="hypertext-arrow-right"
                                                        className="arrow-right"
                                                      />
                                                    </Link>
                                                  </Col>
                                                </Row>
                                              </div>
                                            )
                                          })}
                                        </Col>

                                        <Col lg={12} className="tabs mt60">
                                          <Slide delay="200">
                                            <div className="d-dsk tab-wrapper">
                                              <div className="fpd-wrap fe-gl-nav-dsk justify-content-between">
                                                {navigationLinks.map(
                                                  (el, id) => {
                                                    return (
                                                      <div
                                                        className={`fgl-nav p24
                                        ${id === activeTab && "active"}
                                          `}
                                                        onClick={() => {
                                                          setActiveTab(id)
                                                          featureTabScroll()
                                                        }}
                                                        onKeyDown={() => {
                                                          setActiveTab(id)
                                                          featureTabScroll()
                                                        }}
                                                        role="button"
                                                        tabIndex={0}
                                                      >
                                                        <div className="at-line" />
                                                        <p className="fw-600  m-0 mt24">
                                                          {el.heading}
                                                        </p>
                                                      </div>
                                                    )
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </Slide>
                                        </Col>
                                      </Fade>
                                    </Row>
                                  </Container>
                                )
                              )
                            }}
                          </TrackVisibility>
                        </section>
                        <div id="featureTabSection"></div>
                      </LazyLoad>
                      <LazyLoad>
                        <section className="integrations-section">
                          <div className="wrapper mx-auto">
                            <Col
                              lg={12}
                              className="right prelative"
                              style={{ height: "600px" }}
                            >
                              <div className="prelative d-flex flex-column integration-parent-wrapper">
                                <IntegrationsCardNew data={layer1} />
                                <IntegrationsCardNew data={layer2} />
                                <IntegrationsCardNew data={layer3} />
                              </div>
                              <div className="position-absolute line-wrapper"></div>
                            </Col>
                            <Col lg={12} className="left">
                              <div className="position-relative mx-auto">
                                <Fade bottom duration={1000}>
                                  <div className="heading position-relative justify-content-center fw-bold">
                                    {parse(pageBlock[3].content.html)}
                                  </div>
                                </Fade>
                              </div>
                            </Col>
                            <div className="d-flex justify-content-center mt32">
                              <Buttons
                                theme="secondary-new"
                                link={"/marketplace"}
                                text={parse(pageBlock[4].content.html)}
                              />
                            </div>
                          </div>
                        </section>
                      </LazyLoad>
                      <section className="cta">
                        <CTA
                          data={[ctaBoxes[1]]}
                          className="Layout-container"
                          maxDescriptionWidth="500px"
                          lgLeft={7}
                          lgRight={4}
                          rightStyles={{ alignItems: "center" }}
                          newButton
                          newDesign
                        />
                      </section>

                      <section className="">
                        <TrackVisibility
                          once
                          // children
                          partialVisibility={true}
                          offset={100}
                        >
                          {({ isVisible }) => {
                            return (
                              isVisible && (
                                <Container className="Layout-container">
                                  <LatestResources
                                    heading="Blogs, podcasts, templates, and more just for you"
                                    items={data.SuperOps.posts}
                                  />
                                </Container>
                              )
                            )
                          }}
                        </TrackVisibility>
                      </section>

                      <section className="cta">
                        <CTA
                          data={[ctaBoxes[0]]}
                          bgPattern
                          className="Layout-full"
                          maxDescriptionWidth="475px"
                          maxBoxWidth="100%"
                          maxRowWidth="1176px"
                          lgLeft={5}
                          lgRight={7}
                          newButton
                          newDesign
                        />
                      </section>
                    </main>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default RmmTools
